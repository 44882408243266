import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';

@Component({
    selector: 'bv-root',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: '<ion-app><ion-router-outlet /></ion-app>',
    standalone: true,
    imports: [IonApp, IonRouterOutlet],
})
export class BrainlaneVouchersAppComponent {}
