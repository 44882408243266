<ion-content>
    <ion-list class="items-list">
        @for (link of links; track link.route) {
            <ion-item [routerLink]="link.route" color="primary" detail="false" lines="none">
                <ion-icon [icon]="link.icon" class="link-icon" slot="start"></ion-icon>
                <ion-label class="ion-text-wrap">
                    <h2>{{ link.title }}</h2>
                    <p>{{ link.subtitle }}</p>
                </ion-label>
            </ion-item>
        }
    </ion-list>
</ion-content>
