import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular/standalone';
import { OrderService, UserService } from '@mbp/mbp-brainlane-vouchers-api-client';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs';

import { environment } from '../environments/environment';
import {
    convertVoucher,
    convertVoucherFailure,
    convertVoucherSuccess,
    createAccount,
    createAccountFailure,
    createAccountSuccess,
    createOrder,
    createOrderFailure,
    createOrderSuccess,
    getOrderPaymentLink,
    getOrderPaymentLinkFailure,
    getOrderPaymentLinkSuccess,
    getVoucherInfo,
    getVoucherInfoFailure,
    getVoucherInfoSuccess,
    loadCampaignsInWallet,
    loadCampaignsInWalletFailure,
    loadCampaignsInWalletSuccess,
    loadHistory,
    loadHistoryFailure,
    loadHistorySuccess,
    loadMerchants,
    loadMerchantsFailure,
    loadMerchantsSuccess,
    loadOrderDetails,
    loadOrderDetailsFailure,
    loadOrderDetailsSuccess,
    loadOrders,
    loadOrderSettings,
    loadOrderSettingsFailure,
    loadOrderSettingsSuccess,
    loadOrdersFailure,
    loadOrdersSuccess,
    loadTransactionDetails,
    loadTransactionDetailsFailure,
    loadTransactionDetailsSuccess,
    loadTransactions,
    loadTransactionsFailure,
    loadTransactionsSuccess,
    loadVouchersInWallet,
    loadVouchersInWalletFailure,
    loadVouchersInWalletSuccess,
    sendVerificationCode,
    sendVerificationCodeFailure,
    sendVerificationCodeSuccess,
} from './brainlane-vouchers.actions';
import { ErrorService } from './error.service';

@Injectable({ providedIn: 'root' })
export class BrainlaneVouchersEffects {
    private userService = inject(UserService);
    private orderService = inject(OrderService);
    private alertController = inject(AlertController);
    private actions$ = inject(Actions);
    private router = inject(Router);
    private navController = inject(NavController);
    private errorService = inject(ErrorService);

    loadCampaignsInWallet$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadCampaignsInWallet),
            switchMap((action) =>
                this.userService.listWalletCampaigns().pipe(
                    map((data) => loadCampaignsInWalletSuccess({ data })),
                    catchError((err) => this.errorService.handleError(action, loadCampaignsInWalletFailure, err)),
                ),
            ),
        ),
    );

    loadVouchersInWallet$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadVouchersInWallet),
            switchMap((action) =>
                this.userService.listWalletVouchers(action.campaignId).pipe(
                    map((data) => loadVouchersInWalletSuccess({ data })),
                    catchError((err) => this.errorService.handleError(action, loadVouchersInWalletFailure, err)),
                ),
            ),
        ),
    );

    getVoucherInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getVoucherInfo),
            switchMap((action) =>
                this.userService.getVoucherInfo(action.code).pipe(
                    map((data) => getVoucherInfoSuccess({ data })),
                    catchError((err) => this.errorService.handleError(action, getVoucherInfoFailure, err)),
                ),
            ),
        ),
    );

    scanVoucher$ = createEffect(() =>
        this.actions$.pipe(
            ofType(convertVoucher),
            switchMap((action) =>
                this.userService.convertVoucher(action.code).pipe(
                    map(() => convertVoucherSuccess()),
                    tap(() => this.router.navigateByUrl('/wallet')),
                    catchError((err) => this.errorService.handleError(action, convertVoucherFailure, err)),
                ),
            ),
        ),
    );

    getOrderSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadOrderSettings),
            switchMap((action) =>
                this.orderService.getOrderSettings().pipe(
                    map((data) => loadOrderSettingsSuccess({ data })),
                    catchError((err) => this.errorService.handleError(action, loadOrderSettingsFailure, err)),
                ),
            ),
        ),
    );

    createOrder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createOrder),
            switchMap((action) =>
                this.orderService.createOrder(action.order).pipe(
                    map((data) => createOrderSuccess({ data })),
                    tap(() => this.navController.navigateRoot('/')),
                    catchError((err) => this.errorService.handleError(action, createOrderFailure, err)),
                ),
            ),
        ),
    );

    afterOrderCreated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createOrderSuccess),
            map((action) => getOrderPaymentLink({ orderId: action.data.id })),
        ),
    );

    getOrderPaymentLink$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getOrderPaymentLink),
            switchMap((action) =>
                this.orderService.getOrderPaymentLink(action.orderId).pipe(
                    map((data) => getOrderPaymentLinkSuccess({ data })),
                    // Open the payment link in external browser
                    tap((action) => environment.mbpClient.navigation.openExternal(action.data.externalUrl)),
                    catchError((err) => this.errorService.handleError(action, getOrderPaymentLinkFailure, err)),
                ),
            ),
        ),
    );

    loadHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadHistory),
            switchMap((action) =>
                this.orderService.getHistory().pipe(
                    map((data) => loadHistorySuccess({ data })),
                    catchError((err) => this.errorService.handleError(action, loadHistoryFailure, err)),
                ),
            ),
        ),
    );

    loadOrders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadOrders),
            switchMap((action) =>
                this.orderService.listOrders().pipe(
                    map((data) => loadOrdersSuccess({ data })),
                    catchError((err) => this.errorService.handleError(action, loadOrdersFailure, err)),
                ),
            ),
        ),
    );

    loadOrderDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadOrderDetails),
            switchMap((action) =>
                this.orderService.getOrder(action.orderId).pipe(
                    map((data) => loadOrderDetailsSuccess({ data })),
                    catchError((err) => this.errorService.handleError(action, loadOrderDetailsFailure, err)),
                ),
            ),
        ),
    );

    loadTransactions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadTransactions),
            switchMap((action) =>
                this.userService.listTransactions(action.campaignId).pipe(
                    map((data) => loadTransactionsSuccess({ data })),
                    catchError((err) => this.errorService.handleError(action, loadTransactionsFailure, err)),
                ),
            ),
        ),
    );

    loadTransactionDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadTransactionDetails),
            switchMap((action) =>
                this.userService.getTransactionDetails(action.transactionId, action.campaignId).pipe(
                    map((data) => loadTransactionDetailsSuccess({ data })),
                    catchError((err) => this.errorService.handleError(action, loadTransactionDetailsFailure, err)),
                ),
            ),
        ),
    );

    loadMerchants$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadMerchants),
            switchMap((action) =>
                this.userService.listCampaignCompanies(action.campaignId).pipe(
                    map((data) => loadMerchantsSuccess({ data })),
                    catchError((err) => this.errorService.handleError(action, loadMerchantsFailure, err)),
                ),
            ),
        ),
    );

    sendVerificationCode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(sendVerificationCode),
            switchMap((action) =>
                this.userService.sendVerificationCode(action.payload).pipe(
                    map(() => sendVerificationCodeSuccess()),
                    tap(async () => {
                        const alert = await this.alertController.create({
                            header: 'Verificatiecode verzonden',
                            message: `We hebben een verificatiecode naar je e-mailadres ${action.payload.email} gestuurd. Voer de code uit de email hieronder in om je e-mailadres te bevestigen.`,
                            buttons: [{ text: 'Ok' }],
                        });
                        await alert.present();
                    }),
                    catchError((err) => this.errorService.handleError(action, sendVerificationCodeFailure, err)),
                ),
            ),
        ),
    );

    createAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createAccount),
            switchMap((action) =>
                this.userService.createUser(action.payload).pipe(
                    map((payload) => createAccountSuccess({ payload })),
                    tap(() => this.navController.navigateRoot('/')),
                    catchError((err) => this.errorService.handleError(action, createAccountFailure, err)),
                ),
            ),
        ),
    );

    constructor() {
        // TODO not supported by MBP at the moment
        // if (url.includes('order-finished')) {
        //   // Navigate to main page, and show a confirmation modal on top.
        //   this.navController.navigateRoot('/');
        //   this.modalController.create({ component: OrderConfirmedComponent }).then(modal => modal.present());
        // }
    }
}
