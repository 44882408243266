import { effect, inject, Injectable, signal } from '@angular/core';
import { Configuration, CreateAuthSessionResultDto } from '@mbp/mbp-brainlane-vouchers-api-client';

@Injectable({ providedIn: 'root' })
export class OpenApiConfiguration {
    private config = inject(Configuration);
    session = signal<CreateAuthSessionResultDto | null>(null);

    constructor() {
        effect(() => {
            const session = this.session();
            if (session) {
                this.config.credentials = { sessionId: `Bearer ${session.sessionId}` };
            }
        });
    }
}
